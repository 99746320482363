<template>
    <div :class="wrapperClasses">
        <BaseInput
            v-model="bankAccountData.designation"
            :label="$t('organisations.bankAccounts.designation')"
            :placeholder="$t('organisations.bankAccounts.designation')"
            :rules="{required: required, max: 255}"
            :required="required" />

        <BaseInput
            v-model="bankAccountData.iban"
            :label="$t('organisations.bankAccounts.iban')"
            :placeholder="$t('organisations.bankAccounts.iban')"
            :rules="{required: required, max: 255, verify_iban: required}"
            :required="required"
            @blur="formatIban" />

        <BaseInput
            v-model="bankAccountData.account_holder"
            :label="$t('organisations.bankAccounts.account_holder')"
            :placeholder="$t('organisations.bankAccounts.account_holder')"
            :rules="{required: required, max: 255}"
            :required="required" />

        <BaseSwitch
            v-if="!hideDefaultSwitch"
            v-model="bankAccountData.default"
            :label="$t('organisations.bankAccounts.default')"
            :name="$t('organisations.bankAccounts.default')" />
    </div>
</template>

<script setup>
import {friendlyFormatIBAN} from "ibantools";

const emits = defineEmits(['update:modelValue'])

const props = defineProps({
    modelValue: {
        type: Object,
        required: true
    },
    hideDefaultSwitch: {
        type: Boolean,
        default: false
    },
    wrapperClasses: {
        type: String,
        default: 'grid gap-y-8'
    },
    required: {
        type: Boolean,
        default: true
    }
})

const bankAccountData = computed({
    get: () => props.modelValue,
    set: (newValue) => emits('update:modelValue', newValue)
})

const formatIban = () => {
    if (bankAccountData.value.iban) {
        bankAccountData.value.iban = friendlyFormatIBAN(bankAccountData.value.iban)
    }
}

</script>

<style scoped>

</style>