import {defineStore} from 'pinia'
import {useNotification} from '@/composables/useNotification'
import {useNuxtApp} from 'nuxt/app'
import {ref} from 'vue'
import {$lara, $larafetch} from '@/utils/$larafetch'

export const useBankAccountStore = defineStore('bankAccount', () => {
    const allBankAccounts = ref([])
    const bankAccountsLoaded = ref(false)
    const bankAccountToEdit = ref(null)
    const bankAccountToDelete = ref(null)
    const bankAccountDoesNotExist = ref(false)
    const loadingSubmit = ref(false)
    const loadingDelete = ref(false)
    const lastStoredBankAccount = ref(null)

    function storeAllBankAccounts(response) {
        allBankAccounts.value = response.data
        bankAccountsLoaded.value = true
        bankAccountDoesNotExist.value = false
    }

    function handleRequestError(error) {
        const notification = useNotification()
        const app = useNuxtApp()
        bankAccountsLoaded.value = true
        loadingSubmit.value = false
        if (error && error.response && error.response.status === 422) {
            notification.error(app.$i18n.t('invalidRequestData') + '\n' + error.response._data.message)
        } else if (error && error.response && error.response.status === 404) {
            bankAccountDoesNotExist.value = true
            notification.error(app.$i18n.t('modelDoesNotExist'))
        }
        throw error
    }

    function fetchAllBankAccounts() {
        bankAccountsLoaded.value = false
        $larafetch(useNuxtApp().$apiRoute('spa.settings.bankAccounts.index'))
            .then(storeAllBankAccounts)
            .catch(handleRequestError)
    }

    async function storeBankAccountData(organisationId) {
        loadingSubmit.value = true

        await $lara.post(useNuxtApp().$apiRoute('spa.settings.bankAccounts.store'),
            {
                body: {
                    ...bankAccountToEdit.value,
                    organisation_id: organisationId,
                }
            }).then((response) => {
                const notification = useNotification()
                const app = useNuxtApp()
                notification.success(app.$i18n.t('organisations.bankAccounts.successfullySaved'))

                loadingSubmit.value = false
                bankAccountToEdit.value = null
                lastStoredBankAccount.value = response.data

                fetchAllBankAccounts()
            })
            .catch(handleRequestError)
    }

    async function updateBankAccountData() {
        await $lara.put(useNuxtApp().$apiRoute('spa.settings.bankAccounts.update', {bankAccount: bankAccountToEdit.value.id}), {
            body: bankAccountToEdit.value
        }).then(() => {
            const notification = useNotification()
            const app = useNuxtApp()
            notification.success(app.$i18n.t('organisations.bankAccounts.successfullySaved'))

            loadingSubmit.value = false
            bankAccountToEdit.value = null

            fetchAllBankAccounts()
        }).catch(handleRequestError)
    }

    async function deleteBankAccountData() {
        loadingDelete.value = true

        $lara.delete(useNuxtApp().$apiRoute('spa.settings.bankAccounts.destroy', {bankAccount: bankAccountToDelete.value.id})
        ).then(() => {
            const notification = useNotification()
            const app = useNuxtApp()
            notification.success(app.$i18n.t('organisations.bankAccounts.successfullyDeleted'))

            bankAccountToDelete.value = null
            loadingDelete.value = false

            fetchAllBankAccounts()
        }).catch(handleRequestError)
    }

    function resetData() {
        allBankAccounts.value = null
        bankAccountsLoaded.value = false
        bankAccountToEdit.value = null
        bankAccountToDelete.value = null
        bankAccountDoesNotExist.value = false
        loadingSubmit.value = false
        loadingDelete.value = false
        lastStoredBankAccount.value = null
    }

    return {
        allBankAccounts,
        bankAccountsLoaded,
        bankAccountToEdit,
        bankAccountToDelete,
        bankAccountDoesNotExist,
        loadingSubmit,
        loadingDelete,
        lastStoredBankAccount,
        fetchAllBankAccounts,
        storeBankAccountData,
        updateBankAccountData,
        deleteBankAccountData,
        resetData,
    }
})
